// Notification.js
import React from 'react';
import './Notification.css'; // Make sure to create this CSS file

const Notification = ({ message, onClose }) => {
  return (
    <div className="notification" onClick={onClose}>
      <div className="content">
        <div className="identifier"></div>
        <div className="text">{message}</div>
      </div>
      <button className="close-button" onClick={onClose}>×</button> {/* Close button added */}
    </div>
  );
};

export default Notification;
