import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { ref, onValue, update } from "firebase/database";
import { database } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import "./ProfilePage.css";

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [userEmailKey, setUserEmailKey] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Personal Information"); // Track selected tab

  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const userEmailKey = user.email.replace(".", ",");
      setUserEmailKey(userEmailKey);

      const profileRef = ref(database, `profiles/${userEmailKey}`);
      const unsubscribe = onValue(
        profileRef,
        (snapshot) => {
          if (snapshot.exists()) {
            setProfile(snapshot.val());
          } else {
            setError("Profile does not exist.");
          }
          setLoading(false);
        },
        (error) => {
          setError("Error fetching profile: " + error.message);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    } else {
      setError("User not authenticated. Redirecting to sign-in...");
      navigate("/sign-in");
      setLoading(false);
    }
  }, [user, navigate]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing); // Toggle edit mode
  };

  const handleProfileChange = (e, section, index, field) => {
    const newProfile = { ...profile };
    if (index !== undefined) {
      newProfile[section][index][field] = e.target.value;
    } else {
      newProfile[section] = e.target.value;
    }
    setProfile(newProfile);
  };

  const addField = (section) => {
    const newProfile = { ...profile };
    if (
      section === "education" ||
      section === "workExperience" ||
      section === "projects" ||
      section === "certifications" ||
      section === "languages"
    ) {
      newProfile[section].push({});
    }
    setProfile(newProfile);
  };

  const removeField = (section, index) => {
    const newProfile = { ...profile };
    newProfile[section].splice(index, 1);
    setProfile(newProfile);
  };

  const handleSave = () => {
    const profileRef = ref(database, `profiles/${userEmailKey}`);
    update(profileRef, profile)
      .then(() => {
        alert("Profile updated successfully!");
        setIsEditing(false);
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        alert("Error updating profile.");
      });
  };

  const handleHome = () => {
    navigate("/", { state: { email: user.email, userEmailKey: userEmailKey } }); // Redirect to home page
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/"); // Redirect to sign-in page after logout
      })
      .catch((error) => {
        console.error("Error logging out:", error);
        alert("Error logging out.");
      });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container">
<div className="profile-header">
  <span className="navbar-title" onClick={() => navigate("/")}>
    <b>
      <span className="brand-highlight">fujm</span> - Seeker Dashboard
    </b>
  </span>
  <div className="header-buttons">
    <button onClick={handleHome} className="header-button">
      Home
    </button>
    <button className="header-button" onClick={handleEditToggle}>
      {isEditing ? "Cancel" : "Edit Profile"}
    </button>

    {isEditing && (
      <button onClick={handleSave} className="header-button">
        Save
      </button>
    )}

    <button onClick={handleLogout} className="header-button logout-button">
      Logout
    </button>
  </div>
</div>

      {/* Tabs for navigation */}

      {/* Profile Content */}
      <div className="main-content">
      <div className="profile-grid">
        <div className="profile-sidebar">
        <div className="tabs">
  {[
    "Personal Information",
    "Address",
    "Education",
    "Work Experience",
    "Skills",
    "Certifications",
    "Projects",
    "Languages",
  ].map((tab) => (
    <button
      key={tab}
      className={`tab-btn ${selectedTab === tab ? "active" : ""}`}
      onClick={() => {
        console.log("Clicked tab:", tab); // Debugging line
        setSelectedTab(tab);
      }}
      style={{ zIndex: 10 }} // Temporarily add style to rule out overlap issues
    >
      {tab}
    </button>
  ))}
</div>
        </div>
        <div className="profile-content">
          {selectedTab === "Personal Information" && (
            <div className="profile-section">
              <h2>Personal Information</h2>
              <p>
                <strong>First Name:</strong>{" "}
                {isEditing ? (
                  <input
                    type="text"
                    value={profile["First Name"]}
                    onChange={(e) => handleProfileChange(e, "First Name")}
                  />
                ) : (
                  profile["First Name"]
                )}
              </p>
              <p>
                <strong>Middle Name:</strong>{" "}
                {isEditing ? (
                  <input
                    type="text"
                    value={profile["Middle Name"]}
                    onChange={(e) => handleProfileChange(e, "Middle Name")}
                  />
                ) : (
                  profile["Middle Name"]
                )}
              </p>
              <p>
                <strong>Last Name:</strong>{" "}
                {isEditing ? (
                  <input
                    type="text"
                    value={profile["Last Name"]}
                    onChange={(e) => handleProfileChange(e, "Last Name")}
                  />
                ) : (
                  profile["Last Name"]
                )}
              </p>
              <p>
                <strong>Email:</strong> {profile.email}
              </p>
              <p>
                <strong>Phone:</strong>{" "}
                {isEditing ? (
                  <input
                    type="text"
                    value={profile.phone}
                    onChange={(e) => handleProfileChange(e, "phone")}
                  />
                ) : (
                  profile.phone
                )}
              </p>
            </div>
          )}

          {selectedTab === "Address" && (
            <div className="profile-section">
              <h2>Address</h2>
              <p>
                <strong>Street:</strong>{" "}
                {isEditing ? (
                  <input
                    type="text"
                    value={profile.address?.street || ""}
                    onChange={(e) =>
                      handleProfileChange(e, "address", undefined, "street")
                    }
                  />
                ) : (
                  profile.address?.street
                )}
              </p>
              <p>
                <strong>City:</strong>{" "}
                {isEditing ? (
                  <input
                    type="text"
                    value={profile.address?.city || ""}
                    onChange={(e) =>
                      handleProfileChange(e, "address", undefined, "city")
                    }
                  />
                ) : (
                  profile.address?.city
                )}
              </p>
              <p>
                <strong>State:</strong>{" "}
                {isEditing ? (
                  <input
                    type="text"
                    value={profile.address?.state || ""}
                    onChange={(e) =>
                      handleProfileChange(e, "address", undefined, "state")
                    }
                  />
                ) : (
                  profile.address?.state
                )}
              </p>
              <p>
                <strong>Zip Code:</strong>{" "}
                {isEditing ? (
                  <input
                    type="text"
                    value={profile.address?.zipCode || ""}
                    onChange={(e) =>
                      handleProfileChange(e, "address", undefined, "zipCode")
                    }
                  />
                ) : (
                  profile.address?.zipCode
                )}
              </p>
              <p>
                <strong>Country:</strong>{" "}
                {isEditing ? (
                  <input
                    type="text"
                    value={profile.address?.country || ""}
                    onChange={(e) =>
                      handleProfileChange(e, "address", undefined, "country")
                    }
                  />
                ) : (
                  profile.address?.country
                )}
              </p>
            </div>
          )}

          {selectedTab === "Education" && (
            <div className="profile-section">
              <h2>Education</h2>
              {profile.education?.map((edu, index) => (
                <div key={index} className="profile-item">
                  <p>
                    <strong>Degree:</strong>{" "}
                    {isEditing ? (
                      <input
                        type="text"
                        value={edu.degree || ""}
                        onChange={(e) =>
                          handleProfileChange(e, "education", index, "degree")
                        }
                      />
                    ) : (
                      edu.degree
                    )}
                  </p>
                  <p>
                    <strong>Field of Study:</strong>{" "}
                    {isEditing ? (
                      <input
                        type="text"
                        value={edu.fieldOfStudy || ""}
                        onChange={(e) =>
                          handleProfileChange(
                            e,
                            "education",
                            index,
                            "fieldOfStudy"
                          )
                        }
                      />
                    ) : (
                      edu.fieldOfStudy
                    )}
                  </p>
                  <p>
                    <strong>Institution:</strong>{" "}
                    {isEditing ? (
                      <input
                        type="text"
                        value={edu.institution || ""}
                        onChange={(e) =>
                          handleProfileChange(
                            e,
                            "education",
                            index,
                            "institution"
                          )
                        }
                      />
                    ) : (
                      edu.institution
                    )}
                  </p>
                  <p>
                    <strong>Graduation Year:</strong>{" "}
                    {isEditing ? (
                      <input
                        type="text"
                        value={edu.graduationYear || ""}
                        onChange={(e) =>
                          handleProfileChange(
                            e,
                            "education",
                            index,
                            "graduationYear"
                          )
                        }
                      />
                    ) : (
                      edu.graduationYear
                    )}
                  </p>
                  {isEditing && (
                    <button
                      className="custom-btn btn-4"
                      onClick={() => removeField("education", index)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              {isEditing && (
                <button
                  className="custom-btn btn-4"
                  onClick={() => addField("education")}
                >
                  Add Education
                </button>
              )}
            </div>
          )}
          {selectedTab === "Work Experience" && (
            <div className="profile-section">
              <h2>Work Experience</h2>
              {profile.workExperience?.length > 0 ? (
                profile.workExperience.map((work, index) => (
                  <div key={index} className="profile-item">
                    <p>
                      <strong>Job Title:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={work.jobTitle || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "workExperience",
                              index,
                              "jobTitle"
                            )
                          }
                        />
                      ) : (
                        work.jobTitle
                      )}
                    </p>
                    <p>
                      <strong>Company:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={work.company || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "workExperience",
                              index,
                              "company"
                            )
                          }
                        />
                      ) : (
                        work.company
                      )}
                    </p>
                    <p>
                      <strong>Location:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={work.location || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "workExperience",
                              index,
                              "location"
                            )
                          }
                        />
                      ) : (
                        work.location
                      )}
                    </p>
                    <p>
                      <strong>Start Date:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={work.startDate || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "workExperience",
                              index,
                              "startDate"
                            )
                          }
                        />
                      ) : (
                        work.startDate
                      )}
                    </p>
                    <p>
                      <strong>End Date:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={work.endDate || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "workExperience",
                              index,
                              "endDate"
                            )
                          }
                        />
                      ) : (
                        work.endDate
                      )}
                    </p>
                    {isEditing && (
                      <button
                        className="custom-btn btn-4"
                        onClick={() => removeField("workExperience", index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))
              ) : (
                <p>No work experience available.</p>
              )}
              {isEditing && (
                <button
                  className="custom-btn btn-4"
                  onClick={() => addField("workExperience")}
                >
                  Add Work Experience
                </button>
              )}
            </div>
          )}

          {selectedTab === "Skills" && (
            <div className="profile-section">
              <h2>Skills</h2>
              <div className="skills-section">
                {profile.skills?.length > 0 ? (
                  profile.skills.map((skill, index) => (
                    <div key={index} className="skill">
                      <p>
                        {isEditing ? (
                          <input
                            type="text"
                            value={skill || ""}
                            onChange={(e) =>
                              handleProfileChange(e, "skills", index)
                            }
                          />
                        ) : (
                          skill
                        )}
                      </p>
                      {isEditing && (
                        <button
                          className="custom-btn btn-4"
                          onClick={() => removeField("skills", index)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No skills available.</p>
                )}
              </div>
              {isEditing && (
                <button
                  className="custom-btn btn-4"
                  onClick={() => addField("skills")}
                >
                  Add Skill
                </button>
              )}
            </div>
          )}

          {selectedTab === "Certifications" && (
            <div className="profile-section">
              <h2>Certifications</h2>
              {profile.certifications?.length > 0 ? (
                profile.certifications.map((cert, index) => (
                  <div key={index} className="profile-item">
                    <p>
                      <strong>Certification:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={cert.name || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "certifications",
                              index,
                              "name"
                            )
                          }
                        />
                      ) : (
                        cert.name
                      )}
                    </p>
                    <p>
                      <strong>Issuing Organization:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={cert.issuingOrganization || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "certifications",
                              index,
                              "issuingOrganization"
                            )
                          }
                        />
                      ) : (
                        cert.issuingOrganization
                      )}
                    </p>
                    <p>
                      <strong>Issue Date:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={cert.issueDate || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "certifications",
                              index,
                              "issueDate"
                            )
                          }
                        />
                      ) : (
                        cert.issueDate
                      )}
                    </p>
                    <p>
                      <strong>Expiration Date:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={cert.expirationDate || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "certifications",
                              index,
                              "expirationDate"
                            )
                          }
                        />
                      ) : (
                        cert.expirationDate
                      )}
                    </p>
                    {isEditing && (
                      <button
                        className="custom-btn btn-4"
                        onClick={() => removeField("certifications", index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))
              ) : (
                <p>No certifications available.</p>
              )}
              {isEditing && (
                <button
                  className="custom-btn btn-4"
                  onClick={() => addField("certifications")}
                >
                  Add Certification
                </button>
              )}
            </div>
          )}
          {selectedTab === "Projects" && (
            <div className="profile-section">
              <h2>Projects</h2>
              {profile.projects?.length > 0 ? (
                profile.projects.map((project, index) => (
                  <div key={index} className="profile-item">
                    <p>
                      <strong>Project Name:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={project.title || ""}
                          onChange={(e) =>
                            handleProfileChange(e, "projects", index, "title")
                          }
                        />
                      ) : (
                        project.title
                      )}
                    </p>
                    <p>
                      <strong>Description:</strong>
                      {isEditing ? (
                        <textarea
                          value={project.description || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "projects",
                              index,
                              "description"
                            )
                          }
                        />
                      ) : (
                        project.description
                      )}
                    </p>
                    <p>
  <strong>Technologies:</strong>
  {isEditing ? (
    <input
      type="text"
      value={Array.isArray(project.technologiesUsed) ? project.technologiesUsed.join(", ") : ""}
      onChange={(e) =>
        handleProfileChange(
          e,
          "projects",
          index,
          "technologiesUsed",
          e.target.value.split(", ")
        )
      }
      placeholder="Comma-separated values"
    />
  ) : (
    Array.isArray(project.technologiesUsed) ? project.technologiesUsed.join(", ") : "N/A"
  )}
</p>
                    {project.link && (
                      <p>
                        <strong>Link:</strong>{" "}
                        <a
                          href={project.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {project.link}
                        </a>
                      </p>
                    )}
                    {isEditing && (
                      <button
                        className="custom-btn btn-4"
                        onClick={() => removeField("projects", index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))
              ) : (
                <p>No projects available.</p>
              )}
              {isEditing && (
                <button
                  className="custom-btn btn-4"
                  onClick={() => addField("projects")}
                >
                  Add Project
                </button>
              )}
            </div>
          )}

          {selectedTab === "Languages" && (
            <div className="profile-section">
              <h2>Languages</h2>
              {profile.languages?.length > 0 ? (
                profile.languages.map((language, index) => (
                  <div key={index} className="profile-item">
                    <p>
                      <strong>Language:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={language.name || ""}
                          onChange={(e) =>
                            handleProfileChange(e, "languages", index, "name")
                          }
                        />
                      ) : (
                        language.name
                      )}
                    </p>
                    <p>
                      <strong>Proficiency:</strong>
                      {isEditing ? (
                        <input
                          type="text"
                          value={language.proficiency || ""}
                          onChange={(e) =>
                            handleProfileChange(
                              e,
                              "languages",
                              index,
                              "proficiency"
                            )
                          }
                        />
                      ) : (
                        language.proficiency
                      )}
                    </p>
                    {isEditing && (
                      <button
                        className="custom-btn btn-4"
                        onClick={() => removeField("languages", index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))
              ) : (
                <p>No languages available.</p>
              )}
              {isEditing && (
                <button
                  className="custom-btn btn-4"
                  onClick={() => addField("languages")}
                >
                  Add Language
                </button>
              )}
            </div>
          )}
        </div>
      </div>
</div>
    </div>
  );
};

export default ProfilePage;
