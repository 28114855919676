import React, { useState } from "react";
import mammoth from "mammoth";
import * as pdfjsLib from "pdfjs-dist/webpack";

const UploadFile = () => {
  const [file, setFile] = useState(null);
  const [fileText, setFileText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [extractedSkills, setExtractedSkills] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError("");
    setFileText("");
    setExtractedSkills("");
  };

  const extractPDFText = async (file) => {
    setLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = async function () {
      try {
        const typedArray = new Uint8Array(this.result);
        const pdf = await pdfjsLib.getDocument(typedArray).promise;
        let extractedText = "";

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map((item) => item.str).join(" ");
          extractedText += pageText + "\n";
        }

        setFileText(extractedText);
        setLoading(false);
      } catch (err) {
        setError("Error extracting text from PDF.");
        setLoading(false);
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  const extractDOCXText = async (file) => {
    setLoading(true);
    try {
      const arrayBuffer = await file.arrayBuffer();
      const { value } = await mammoth.extractRawText({ arrayBuffer });
      setFileText(value);
    } catch (err) {
      setError("Error extracting text from DOCX.");
    }
    setLoading(false);
  };

  const handleFileUpload = () => {
    if (!file) {
      setError("Please select a file.");
      return;
    }

    const fileType = file.name.split(".").pop();
    if (fileType === "pdf") {
      extractPDFText(file);
    } else if (fileType === "docx") {
      extractDOCXText(file);
    } else {
      setError("Unsupported file type. Please upload a PDF or DOCX file.");
    }
  };

  const extractSkills = async () => {
    if (!fileText) {
      setError("No text extracted from resume.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch("http://localhost:3002/api/extract-skills", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: fileText }),
      });

      if (!response.ok) {
        throw new Error("Error extracting skills.");
      }

      const data = await response.json();
      setExtractedSkills(data.skills);
    } catch (err) {
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="upload-container">
      <h2>Upload Your Resume</h2>
      <input type="file" accept=".pdf, .docx" onChange={handleFileChange} className="file-input" />
      <button onClick={handleFileUpload} disabled={loading} className="upload-button">
        {loading ? "Extracting text..." : "Upload and Extract Text"}
      </button>

      {error && <p className="error-message">{error}</p>}
      {fileText && (
        <div className="file-text">
          <h3>Extracted Text</h3>
          <pre>{fileText}</pre>
        </div>
      )}

      {fileText && (
        <button onClick={extractSkills} disabled={loading} className="skill-button">
          {loading ? "Extracting skills..." : "Extract Skills"}
        </button>
      )}

      {extractedSkills && (
        <div className="extracted-skills">
          <h3>Extracted Skills</h3>
          <pre>{extractedSkills}</pre>
        </div>
      )}
    </div>
  );
};

export default UploadFile;
