import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set, remove, push, get } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCnKkk81_eFnOyHNFGHBO-_P762mH755Ss",
  authDomain: "cream-f02ba.firebaseapp.com",
  databaseURL: "https://cream-f02ba-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cream-f02ba",
  storageBucket: "cream-f02ba.appspot.com",
  messagingSenderId: "635302272338",
  appId: "1:635302272338:web:b429bada92d1e6b492ae8b",
  measurementId: "G-039VHY43L9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Firebase Realtime Database
const database = getDatabase(app);

// Export the required Firebase services
export { auth, database, ref, set, remove, push, get };
