import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import "./App.css";
import JobListPage from "./components/JobListPage";
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import UploadFile from "./components/UploadFile";
import "bootstrap/dist/css/bootstrap.min.css";
import SignInSignUp from "./components/SignInSignUp";
import UserDashboard from "./components/UserDashboard";
import Wall from "./components/Wall";
import QuickSignInModal from "./components/QuickSignInModal";
import ProfilePage from "./components/ProfilePage";

const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [datePosted, setDatePosted] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [userEmail, setUserEmail] = useState(null); // State to store the user email
  const [userEmailKey, setuserEmailKey] = useState(null); // State to store the user email

  const navigate = useNavigate();
  const location = useLocation();
  const [isQuickSignInOpen, setQuickSignInOpen] = useState(false);

  const toggleQuickSignIn = () => {
    setQuickSignInOpen((prevState) => !prevState);
  };
  useEffect(() => {
    // Check if user email is passed via navigate state
    if (location.state && location.state.email) {
      setUserEmail(location.state.email);
      console.log("User email set:", location.state.email); // Log when userEmail is set
    }
    if (location.state && location.state.userEmailKey) {
      setuserEmailKey(location.state.userEmailKey);
      console.log("User email key set:", location.state.userEmailKey); // Log when userEmailKey is set
    }
  }, [location.state]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleLocationSelect = (place) => {
    if (place && place.formatted_address) {
      setSelectedLocation(place.formatted_address);

      console.log("Selected location:", place);
    } else {
      console.log("No valid location data returned.");
    }
  };

  const handleSearchRedirect = () => {
    navigate("/joblist", { state: { searchTerm, selectedLocation } });
  };
  const handleSearch = () => {
    console.log("Search function triggered.");
    if (!searchTerm || !selectedLocation) {
      alert("Please enter both search term and location.");
      return;
    }

    // Construct the search URL
    const searchUrl = `/jobs?search=${encodeURIComponent(
      searchTerm
    )}&location=${encodeURIComponent(
      selectedLocation
    )}&datePosted=${encodeURIComponent(datePosted)}`;

    // Navigate with or without state based on userEmail and userEmailKey availability
    if (userEmail && userEmailKey) {
      navigate(searchUrl, {
        state: { email: userEmail, userEmailKey },
      });
    } else {
      navigate(searchUrl); // Navigate without state if email or key is missing
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleLogout = () => {
    setUserEmail(null);
    navigate("/"); // Optionally navigate to the home page or login page after logout
  };

  return (
    <div className="fujm-container">
      {/* Conditional rendering based on whether the user is logged in */}
      {userEmail ? (
        <div className="auth-section">
          <button className="auth-link2" onClick={() => navigate("/fujm-wall")}>
            fujm Wall
          </button>
          <button className="auth-link" onClick={() => navigate("/profile")}>
            Profile
          </button>
          <button className="auth-link" onClick={handleLogout}>
            Logout
          </button>

        </div>
      ) : (
        <div className="auth-section">
          <button className="auth-link2" onClick={() => navigate("/fujm-wall")}>
          fujm Wall
          </button>
          <button className="auth-link2" onClick={toggleQuickSignIn}>
          Quick Sign In
          </button>
          <button className="auth-link1" onClick={() => navigate("/login")}>
            Login / Sign Up
          </button>
        </div>
      )}

      {/* Quick SignIn Modal Start */}
      {isQuickSignInOpen && (
        <QuickSignInModal onClose={toggleQuickSignIn} />
      )}
      {/* Quick SignIn Modal Close */}

      <div className="navbar1">
        <div className="logo-holder logo-2">
          <h3>fujm</h3>
          <p>{userEmail ? userEmail : "for seekers by seeker"}</p>{" "}
          {/* Show userEmail if available, else default text */}{" "}
        </div>
      </div>

      {/* {new code start} */}
      {/* {new code end} */}

      <div className="search-bar-container">
        <input
          type="text"
          className="search-bar"
          placeholder="Enter Job Position"
          onKeyPress={handleKeyPress}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="filter-container">
        <GooglePlacesAutocomplete
          apiKey="AIzaSyBhOM8A9bFdMx6ixBc2FqoR-9oPge8hyEM"
          onPlaceSelected={handleLocationSelect}
          options={{
            types: ["(cities)"], // Restrict to cities only
          }}
          placeholder="Type a location..."
          className="location-input"
        />{" "}
      </div>

      <div className="button-container">
        <button className="button search-button" onClick={handleSearch}>
          fujm Search
        </button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Your Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UploadFile />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const MainApp = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/jobs" element={<JobListPage />} />
        <Route path="/login" element={<SignInSignUp />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/user-dashboard" element={<UserDashboard />} />
        {/* Add route for fujm Wall page */}
        <Route path="/fujm-wall" element={<Wall />} />
      </Routes>
    </Router>
  );
};

export default MainApp;
