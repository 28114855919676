import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, sendPasswordResetEmail } from 'firebase/auth'; // Import sendPasswordResetEmail
import { ref, onValue } from 'firebase/database'; // Import functions for database operations
import { auth, database } from '../firebaseConfig'; // Ensure database is imported
import './SignInSignUp.css';
import Notification from './Notification'; 

const SignInSignUp = () => {
  const [isSignIn, setIsSignIn] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [notification, setNotification] = useState({ message: '', visible: false });
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false); // For password reset
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(''); // Email for reset
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()]/.test(password);
    
    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters.`;
    } else if (!hasUpperCase) {
      return 'Password must contain at least one uppercase letter.';
    } else if (!hasNumber) {
      return 'Password must contain at least one numeric character.';
    } else if (!hasSpecialChar) {
      return 'Password must contain at least one special character.';
    }
    return '';
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      await setPersistence(auth, browserLocalPersistence);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if the user's profile exists in the database
      const userEmailKey = email.replace('.', ','); // Firebase doesn't allow dots in keys
      const profileRef = ref(database, `profiles/${userEmailKey}`);
      
      onValue(profileRef, (snapshot) => {
        if (snapshot.exists()) {
          // Navigate to user dashboard if profile exists
          navigate('/profile', { state: { email: user.email } });
        } else {
          // Navigate to profile creation page if profile does not exist
          navigate('/user-dashboard', { state: { email: user.email } });
        }
      });
    } catch (error) {
      console.error("Error signing in:", error);
      setNotification({ message: 'Invalid credentials. Please try again.', visible: true });
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    const error = validatePassword(password);
    setPasswordError(error);

    if (error) {
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match.');
    } else {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await sendEmailVerification(user);
        setNotification({ message: 'Verification email sent! Please check your inbox.', visible: true });
        setIsSignIn(true);
      } 
      catch (error) {
        let errorMessage;
        if (error.code === 'auth/email-already-in-use') {
          errorMessage = "User Already Exists";
        } else {
          errorMessage = "Error creating account: " + error.message;
        }
        setNotification({ message: errorMessage, visible: true });
      }
    }
  };

  // Function to send password reset email
  const handleForgotPassword = async () => {
    if (!forgotPasswordEmail) {
      setNotification({ message: 'Please enter your email address.', visible: true });
      return;
    }
    try {
      await sendPasswordResetEmail(auth, forgotPasswordEmail);
      setNotification({ message: 'Password reset email sent! Please check your inbox.', visible: true });
      setShowForgotPasswordModal(false); // Close modal after sending the email
    } catch (error) {
      console.error('Error sending reset email:', error);
      setNotification({ message: 'Error sending reset email. Please try again.', visible: true });
    }
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  return (
    <div className="sign-in-sign-up-grid">
        <Link to="/" className="back-arrow">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="white" // Set the fill color to white
                viewBox="0 0 24 24"
            >
                <path d="M12 3l9 9h-3v9h-12v-9h-3z" />
            </svg>
        </Link>
        <div className="info-column">
        <h1>Welcome to fujm</h1>
        <p>Recruit a Seeker out there</p>
        <button className="main-button" onClick={() => setNotification({ message: 'Feature Coming Soon', visible: true })}>
          Recruit a Seeker
        </button>
      </div>

      <div className="form-column">
        <div className="form-container">
          {isSignIn ? (
            <>
              <h2>Seeker Sign In</h2>
              <form className="auth-form" onSubmit={handleSignIn}>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="password-container">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <i
                    className={`password-toggle ${passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}`}
                    onClick={togglePasswordVisibility}
                  />
                </div>
                <button type="submit">Sign In</button>
              </form>
              <p>Don't have an account? <button className="styled-link-btn" onClick={() => setIsSignIn(false)}>Sign Up</button></p>
              <a href="#" className="forgot-password" onClick={() => setShowForgotPasswordModal(true)}>Forgot Password?</a>
            </>
          ) : (
            <>
              <h2>Seeker Sign Up</h2>
              <form className="auth-form" onSubmit={handleSignUp}>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="password-container">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <i
                    className={`password-toggle ${passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}`}
                    onClick={togglePasswordVisibility}
                  />
                </div>
                <div className="password-container">
                  <input
                    type={confirmPasswordVisible ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <i
                    className={`password-toggle ${confirmPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}`}
                    onClick={toggleConfirmPasswordVisibility}
                  />
                </div>
                {passwordError && <p className="error-message">{passwordError}</p>}
                <button type="submit">Sign Up</button>
              </form>
              <p>Already have an account? <button className="styled-link-btn" onClick={() => setIsSignIn(true)}>Sign In</button></p>
            </>
          )}
        </div>
      </div>

      {showForgotPasswordModal && (
        <div className="forgot-password-modal">
          <h3>Reset Password</h3>
          <input
            type="email"
            placeholder="Enter your email"
            value={forgotPasswordEmail}
            onChange={(e) => setForgotPasswordEmail(e.target.value)}
            required
          />
          <button onClick={handleForgotPassword}>Send Reset Email</button>
          <button onClick={() => setShowForgotPasswordModal(false)}>Cancel</button>
        </div>
      )}

      {notification.visible && (
        <Notification message={notification.message} onClose={closeNotification} />
      )}
    </div>
  );
};

export default SignInSignUp;
