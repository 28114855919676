import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { database, ref, set } from '../firebaseConfig';
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import Firebase Auth
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import './UserDashboard.css';
import axios from 'axios';

const UserDashboard = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileText, setFileText] = useState("");
  const [error, setError] = useState("");
  const [extractedProfile, setExtractedProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [profileForm, setProfileForm] = useState({
    "First Name": "",
    "Middle Name": "",
    "Last Name": "",
    "email": "",
    "phone": "",
    "address": {
      "street": "",
      "city": "",
      "state": "",
      "zipCode": "",
      "country": ""
    },
    "linkedin": "",
    "website": "",
    "summary": "",
    "education": [
      {
        "degree": "",
        "fieldOfStudy": "",
        "institution": "",
        "location": "",
        "graduationYear": ""
      }
    ],
    "workExperience": [
      {
        "jobTitle": "",
        "company": "",
        "location": "",
        "startDate": "",
        "endDate": "",
        "responsibilities": [""]
      }
    ],
    "skills": [""],
    "certifications": [
      {
        "title": "",
        "issuingOrganization": "",
        "issueDate": "",
        "expirationDate": ""
      }
    ],
    "projects": [
      {
        "title": "",
        "description": "",
        "technologiesUsed": [""],
        "link": ""
      }
    ],
    "languages": [
      {
        "language": "",
        "proficiency": ""
      }
    ],
    "interests": [""]
  });
  const [userEmail, setUserEmail] = useState(""); // Store user email

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
      } else {
        navigate('/signin');
      }
    });
  }, [navigate]);

  const handleLogout = () => {
    navigate('/');
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError("");
    setFileText("");
    setExtractedProfile({});
    setShowFullText(false);
    if (e.target.files[0]) {
      extractText(e.target.files[0]);
    }
  };

  const extractPDFText = async (file) => {
    const fileReader = new FileReader();
    fileReader.onload = async function () {
      try {
        const typedArray = new Uint8Array(this.result);
        const pdf = await pdfjsLib.getDocument(typedArray).promise;
        let extractedText = "";

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map((item) => item.str).join(" ");
          extractedText += pageText + "\n";
        }

        setFileText(extractedText);
      } catch (err) {
        setError("Error extracting text from PDF.");
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  const extractDOCXText = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const { value } = await mammoth.extractRawText({ arrayBuffer });
      setFileText(value);
    } catch (err) {
      setError("Error extracting text from DOCX.");
    }
  };

  const extractText = (file) => {
    if (!file) {
      setError("Please select a file.");
      return;
    }

    const fileType = file.name.split(".").pop().toLowerCase();
    if (fileType === "pdf") {
      extractPDFText(file);
    } else if (fileType === "docx") {
      extractDOCXText(file);
    } else {
      setError("Unsupported file type. Please upload a PDF or DOCX file.");
    }
  };

  const handleBuildProfile = async () => {
    if (!fileText) {
      setError("Upload file again.");
      return;
    }

    setLoading(true); // Start loading

    try {
      const response = await axios.post('https://fujm.org/.netlify/functions/profile-builder', {
        text: fileText,
      });

      const profileData = JSON.parse(response.data.profile);
      setExtractedProfile(profileData);
      setProfileForm(profileData); // Initialize form state
    } catch (err) {
      setError("Error building profile: " + err.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleFieldChange = (field, value) => {
    if (field === "address") {
      setProfileForm((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          ...value,
        },
      }));
    } else {
      setProfileForm((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };
  
  const addField = () => {
    const newKey = prompt("Enter the field name:");
    if (newKey) {
      setProfileForm({
        ...profileForm,
        [newKey]: "",
      });
    }
  };

  const removeField = (key) => {
    const { [key]: _, ...newProfileForm } = profileForm;
    setProfileForm(newProfileForm);
  };

  const handleArrayFieldChange = (key, index, subKey, value) => {
    setProfileForm((prev) => {
      const newArray = [...prev[key]];
      if (subKey !== null) {
        newArray[index][subKey] = value; // Update specific property
      } else {
        newArray[index] = value; // Update the string directly
      }
      return { ...prev, [key]: newArray };
    });
  };

  const addArrayField = (key) => {
    let newItem = {};
    if (key === "education") {
      newItem = {
        degree: "",
        fieldOfStudy: "",
        institution: "",
        location: "",
        graduationYear: ""
      };
    } else if (key === "workExperience") {
      newItem = {
        jobTitle: "",
        company: "",
        location: "",
        startDate: "",
        endDate: "",
        responsibilities: [""]
      };
    } else if (key === "skills") {
      newItem = ""; // Skills are strings
    }
   else if (key === "certifications") {
      newItem = {
          title: "",
          issuingOrganization: "",
          issueDate: "",
          expirationDate: ""
      };
  } else if (key === "projects") {
      newItem = {
          title: "",
          description: "",
          technologiesUsed: [""],
          link: ""
      };
  } else if (key === "languages") {
      newItem = {
          language: "",
          proficiency: ""
      };
  } else if (key === "interests") {
      newItem = ""; // Interests are strings
  }

    setProfileForm((prev) => ({
      ...prev,
      [key]: [...prev[key], newItem],
    }));
  };

  const removeArrayField = (key, index) => {
    setProfileForm((prev) => {
      const newArray = [...prev[key]];
      newArray.splice(index, 1);
      return { ...prev, [key]: newArray };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
        try {
            // Use the user's email as the unique key in the database
            const profileRef = ref(database, `profiles/${user.email.replace('.', ',')}`); // Replace '.' to avoid issues in Firebase keys
            await set(profileRef, profileForm);

            navigate('/profile');
          } catch (err) {
            setError("Error updating profile: " + err.message);
        }
    } else {
        setError("User not authenticated. Please sign in.");
    }
};

  const resetState = () => {
    setFile(null);
    setFileText("");
    setError("");
    setExtractedProfile({});
    setProfileForm({
      "First Name": "",
      "Middle Name": "",
      "Last Name": "",
      "email": "",
      "phone": "",
      "address": {
        "street": "",
        "city": "",
        "state": "",
        "zipCode": "",
        "country": ""
      },
      "linkedin": "",
      "website": "",
      "summary": "",
      "education": [
        {
          "degree": "",
          "fieldOfStudy": "",
          "institution": "",
          "location": "",
          "graduationYear": ""
        }
      ],
      "workExperience": [
        {
          "jobTitle": "",
          "company": "",
          "location": "",
          "startDate": "",
          "endDate": "",
          "responsibilities": [""]
        }
      ],
      "skills": [""],
      "certifications": [
        {
          "title": "",
          "issuingOrganization": "",
          "issueDate": "",
          "expirationDate": ""
        }
      ],
      "projects": [
        {
          "title": "",
          "description": "",
          "technologiesUsed": [""],
          "link": ""
        }
      ],
      "languages": [
        {
          "language": "",
          "proficiency": ""
        }
      ],
      "interests": [""] 
    });
    setShowFullText(false);
  };

  return (
    <div className="user-dashboard">
      <div className="profile-header">
        <h2>Seeker Space</h2>
        <button onClick={handleLogout}>Logout</button>
      </div>

      <main className="dashboard-main">
        <section className="file-upload-section">
        <input 
    className="fine-input" 
    type="file" 
    accept=".pdf, .docx" 
    onChange={handleFileChange}
    style={{
        paddingTop: '80px',    // Adjust as needed
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '10px',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: '1px solid #ccc'  // Customize color if needed
    }}
/> 
<button onClick={handleBuildProfile} className="space-btn" disabled={loading}>Build Profile</button>
          <button onClick={resetState} className='space-btn'>Reset</button>
          {error && <p className="error-message">{error}</p>}
        </section>

        {Object.keys(extractedProfile).length > 0 && (
          <form onSubmit={handleSubmit} className="profile-form">
            <h3>Edit Profile:</h3>
            {Object.entries(profileForm).map(([key, value]) => {
              if (Array.isArray(value)) {
                return (
                  <div key={key}>
                    <h4>{key}</h4>
                    {value.map((item, index) => (
                      <div key={index} className="array-item">
                        {typeof item === 'object' ? (
                          Object.entries(item).map(([subKey, subValue]) => (
                            <input
                              key={subKey}
                              type="text"
                              value={subValue}
                              placeholder={subKey}
                              onChange={(e) => handleArrayFieldChange(key, index, subKey, e.target.value)}
                            />
                          ))
                        ) : (
                          <input
                            type="text"
                            value={item}
                            onChange={(e) => handleArrayFieldChange(key, index, null, e.target.value)}
                          />
                        )}
                        <button className="custom-btn-rem btn-4-rem" type="button" onClick={() => removeArrayField(key, index)}>Remove</button>
                      </div>
                    ))}
                    <button className="custom-btn btn-4" type="button" onClick={() => addArrayField(key)}>Add {key.slice(0, -1)}</button>
                  </div>
                );
              }

              return (
                <div key={key}>
                  <label>{key}</label>
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => handleFieldChange(key, e.target.value)}
                  />
                </div>
              );
            })}
            <button type="submit">Save Profile</button>
          </form>
        )}
      </main>
    </div>
  );
};

export default UserDashboard;
