import React from 'react';

function renderComparisonWithPoints(comparisonText) {
    // Step 1: Split the text into numbered points (e.g., '1. ...', '2. ...')
    const points = comparisonText.split(/\d+\.\s/).filter(point => point.trim() !== "");

    // Step 2: Highlight the numerical match percentage in each point
    function highlightPercentages(text) {
        return text.replace(/(\d+%)/g, '<span class="highlight">$1</span>'); // Highlight percentage values
    }

    // Step 3: Render each point as a paragraph with proper formatting
    return points.map((point, index) => (
        <p key={index}>
            <strong>{index + 1}.</strong> <span dangerouslySetInnerHTML={{ __html: highlightPercentages(point) }} />
        </p>
    ));
}

const ComparisonResult = ({ comparisonResult }) => {
    return (
        <div>
            <h3>Percentage Match: {comparisonResult.matchPercentage}%</h3>
            <h4>Detailed Comparison</h4>
            <div className="comparison-content">
                {renderComparisonWithPoints(comparisonResult.comparisonText)}
            </div>
        </div>
    );
};

export default ComparisonResult;
