import React, { useState } from "react";
import "./QuickSignInModal.css";

function QuickSignInModal({ onClose, onSignInSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleOverlayClick = (e) => {
    if (e.target.className === "modal-overlay") {
      onClose();
    }
  };

  const handleSignIn = () => {
    if (!email || !password) {
      setError("Both email and password are required.");
      return;
    }

    const isValidEmail = email.includes("@");
    const isValidPassword = password.length >= 6;

    if (!isValidEmail) {
      setError("Please enter a valid email address.");
      return;
    }

    if (!isValidPassword) {
      setError("Password must be at least 6 characters.");
      return;
    }

    console.log("Email:", email);
    console.log("Password:", password);

    onSignInSuccess();
    onClose();
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        {/* Close Icon */}
        <span className="close-icon" onClick={onClose}>
          &times;
        </span>
        <h2>Quick Sign In</h2>

        {/* Display Error Message */}
        {error && <p className="error-message">{error}</p>}

        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className="eye-icon"
            onClick={() => setShowPassword((prev) => !prev)} // Toggle password visibility
          >
            {showPassword ? "👁️" : "👁️‍🗨️"} {/* Eye icon for show/hide */}
          </span>
        </div>

        <button onClick={handleSignIn}>Sign In</button>

        {/* Forgot Password Link */}
        <a href="#" className="forgot-password-link" onClick={(e) => { e.preventDefault(); alert("Redirecting to Forgot Password"); }}>
          Forgot Password?
        </a>
      </div>
    </div>
  );
}

export default QuickSignInModal;
