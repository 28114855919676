import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchJobs } from "./fetchJobs"; // Import your fetchJobs function
import { database, ref, set, get } from "../firebaseConfig"; // Import Firebase functions
import "./JobListPage.css"; // Import the CSS file for styling
import ComparisonResult from "./ComparisonResult";
import Notification from "./Notification";
import GooglePlacesAutocomplete from "react-google-autocomplete";

const JobListPage = () => {
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [userEmail, setUserEmail] = useState(null);
  const [userEmailKey, setuserEmailKey] = useState(null);
  const [userData, setUserData] = useState(null); // New state for user data
  const [comparisonResult, setComparisonResult] = useState(null);
  const [showComparisonResult, setShowComparisonResult] = useState(false); // New state for showing result
  const [notification, setNotification] = useState({
    message: "",
    visible: false,
  });
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedLocation, setSelectedLocation] = useState(
    location.state?.selectedLocation || ""
  );

  const handleLocationSelect = (place) => {
    if (place && place.formatted_address) {
      setSelectedLocation(place.formatted_address);

      console.log("Selected location:", place);
    } else {
      console.log("No valid location data returned.");
    }
  };
  const handleSignInSignUp = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    setUserEmail(null);
    setuserEmailKey(null);
    navigate("/");
  };

  useEffect(() => {
    // Initialize search term and location
    const queryParams = new URLSearchParams(location.search);
    const initialSearchTerm = queryParams.get("search");
    const initialSelectedLocation = queryParams.get("location");
    const datePosted = queryParams.get("datePosted");
    setSearchTerm(initialSearchTerm || "");
    setSelectedLocation(initialSelectedLocation || "");

    if (location.state) {
      const { email, userEmailKey } = location.state;
      setUserEmail(email);
      setuserEmailKey(userEmailKey);
      console.log("Email:", email);
      console.log("User Email Key:", userEmailKey);
    }

    const fetchJobData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchJobs(
          initialSearchTerm,
          initialSelectedLocation,
          datePosted
        );
        const fetchedJobs = response.jobs_results || [];
        await Promise.all(fetchedJobs.map((job) => pushJobToDatabase(job)));
        setJobs(fetchedJobs);
        setNextPageToken(response.serpapi_pagination?.next_page_token || null);

        if (fetchedJobs.length > 0) {
          await loadJobDetails(fetchedJobs[0].job_id);
        }
      } catch (err) {
        setError("Error fetching jobs");
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [location.state]);

  const pushJobToDatabase = async (job) => {
    const jobRef = ref(database, `jobs/${job.job_id}`);
    await set(jobRef, job);
  };

  const handleCompareQualifications = async () => {
    if (!userEmailKey || !selectedJob) {
      return; // Ensure user is logged in and a job is selected
    }

    // Check if job highlights exist
    let jobQualifications = [];

    if (selectedJob.job_highlights) {
      const qualificationsHighlight = selectedJob.job_highlights.find(
        (highlight) => highlight.title === "Qualifications"
      );
      jobQualifications = qualificationsHighlight
        ? qualificationsHighlight.items
        : []; // Default to an empty array if not found
    }

    // If job highlights are not found, use the job description
    if (jobQualifications.length === 0) {
      jobQualifications = [selectedJob.description];
    }

    const userProfile = await fetchUserData(userEmailKey); // Fetch user qualifications

    try {
      const response = await fetch(
        "https://fujm.org/.netlify/functions/compareQualifications",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userProfile,
            jobQualifications,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to compare qualifications");
      }

      const data = await response.json();
      setComparisonResult(data); // Store the comparison result in state
      setShowComparisonResult(true); // Show comparison results
    } catch (error) {
      console.error(error);
    }
  };

  const loadJobDetails = async (jobId) => {
    setSelectedJobId(jobId);
    const jobRef = ref(database, `jobs/${jobId}`);
    const snapshot = await get(jobRef);
    if (snapshot.exists()) {
      setSelectedJob(snapshot.val());
      setShowComparisonResult(false); // Reset comparison result visibility when a new job is selected
    } else {
      setError("Job not found");
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    setSelectedJob(null); // Reset selected job when searching
    try {
      const response = await fetchJobs(searchTerm, selectedLocation);
      const fetchedJobs = response.jobs_results || [];
      await Promise.all(fetchedJobs.map((job) => pushJobToDatabase(job)));
      setJobs(fetchedJobs);
      setNextPageToken(response.serpapi_pagination?.next_page_token || null);
    } catch (err) {
      setError("Error fetching jobs");
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = async () => {
    if (!nextPageToken) return;

    setLoading(true);
    setError(null);

    try {
      const response = await fetchJobs(
        searchTerm,
        selectedLocation,
        nextPageToken
      );
      const fetchedJobs = response.jobs_results || [];
      await Promise.all(fetchedJobs.map((job) => pushJobToDatabase(job)));
      setJobs((prevJobs) => [...prevJobs, ...fetchedJobs]);
      setNextPageToken(response.serpapi_pagination?.next_page_token || null);
    } catch (err) {
      setError("Error fetching jobs");
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async (userEmailKey) => {
    const userRef = ref(database, `profiles/${userEmailKey}`); // Adjust the path based on your database structure
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      setUserData(snapshot.val());
      return snapshot.val(); // Set the user data
    } else {
      setError("User data not found");
    }
  };
  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  return (
    <div className="job-list-page">
      <div className="navbar">
        <span
          className="navbar-title"
          onClick={() =>
            navigate("/", {
              state: { email: userEmail, userEmailKey: userEmailKey },
            })
          }
        >
          fujm Job Board
        </span>
        <div className="search-container">
        <label className="input">
  <input
    type="text"
    className="search-bar1 input__field"
    placeholder=" " // Placeholder is intentionally left empty for styling
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />
  <span className="input__label">Job title</span>
</label>

<label className="input">
  <GooglePlacesAutocomplete
    apiKey="AIzaSyBhOM8A9bFdMx6ixBc2FqoR-9oPge8hyEM"
    onPlaceSelected={handleLocationSelect}
    options={{
      types: ["(cities)"], // Restrict to cities only
    }}
    placeholder=" " // Placeholder is intentionally left empty for styling
    className="location-input1 input__field"
    defaultValue={selectedLocation}
  />
  <span className="input__label">Location</span>
</label>
          <button className="space-btn search-button" onClick={handleSearch}>
            Search
          </button>

          {userEmail ? (
            <div className="user-controls">
              {/* <span 
                                className="user-email" 
                                onClick={() => navigate('/profile')} // Fetch user data on email click
                                style={{ cursor: 'pointer' }} // Add pointer cursor for interactivity
                            >
                                {userEmail}
                            </span> */}
              <button
                className="space-btn logout-button"
                onClick={() => navigate("/profile")}
              >
                Profile
              </button>
              <button
                className="space-btn logout-button"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          ) : (
            <button
              className="space-btn signin-signup-button"
              onClick={handleSignInSignUp}
            >
              Sign In / Sign Up
            </button>
          )}
        </div>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <div className="job-grid">
        <div className="job-column">
          {jobs.map((job, index) => (
            <div
              key={index}
              className="job-item"
              onClick={() => loadJobDetails(job.job_id)} // Load job details on click
              style={{
                backgroundColor:
                  selectedJobId === job.job_id ? "#c9f0c9" : "transparent", // Change background color if selected
                padding: "10px",
                fontFamily: 'sans-serif',
                fontSize: ".8em", // Add some padding for better clickability
                cursor: "pointer", // Show pointer cursor on hover
              }}
            >
              <h3>{job.title}</h3>
              <p className="company_name_columns_tile">{job.company_name}</p>
            </div>
          ))}
          {nextPageToken && (
            <button className="button load-more" onClick={handleLoadMore}>
              Load More
            </button>
          )}
        </div>

        <div className="details-column" style={{ fontSize: "0.8em", fontFamily: 'open serif',}}>
          {selectedJob && (
            <div className="job-details">
              <h2>{selectedJob.title}</h2>
              <p>
                <strong>Company:</strong> {selectedJob.company_name}
              </p>
              <p>
                <strong>Location:</strong> {selectedJob.location}
              </p>
              <p>
                <strong>Via:</strong> {selectedJob.via || "N/A"}
              </p>

              {selectedJob.apply_options &&
                selectedJob.apply_options.length > 0 && (
                  <div>
                    <strong>Apply Options:</strong>
                    <div className="apply-options-container">
                      {selectedJob.apply_options.map((option, index) => (
                        <button
                          key={index}
                          className="apply_btn"
                          onClick={() => window.open(option.link, "_blank")}
                        >
                          {option.title}
                        </button>
                      ))}
                    </div>
                  </div>
                )}

              {selectedJob.extensions && selectedJob.extensions.length > 0 && (
                <div>
                  <strong>Job Details:</strong>
                  <ul>
                    {selectedJob.extensions.map((ext, index) => (
                      <li key={index}>{ext}</li>
                    ))}
                  </ul>
                </div>
              )}

              <div style={{ paddingBottom: "20px" }}>
                <strong>Compare Qualification:</strong>
                <button
                  className="compare-qualifications-button"
                  onClick={() => {
                    if (userEmail && userEmailKey) {
                      handleCompareQualifications();
                    } else {
                      setNotification({
                        message: "Please log in to compare qualifications.",
                        visible: true,
                      });
                    }
                  }}
                >
                  Compare Qualifications
                </button>
              </div>
              {showComparisonResult && comparisonResult && (
                <div className="comparison-result">
                  <h3>Comparison Result</h3>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {comparisonResult.matchPercentage
                      .split("\n")
                      .map((point) => point.trim())
                      .filter((point) => point !== "")
                      .map((point, index) => (
                        <li key={index}>{point}</li>
                      ))}
                  </ul>
                </div>
              )}
              <h2></h2>
              <p>
                <strong>Description:</strong>{" "}
                {selectedJob.description || "No description available."}
              </p>

              {selectedJob.job_highlights &&
                selectedJob.job_highlights.length > 0 && (
                  <div>
                    <strong>Job Highlights:</strong>
                    {selectedJob.job_highlights.map((highlight, index) => (
                      <div key={index}>
                        <h4>{highlight.title}</h4>
                        <ul>
                          {highlight.items.map((item, itemIndex) => (
                            <li key={itemIndex}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
      {notification.visible && (
        <Notification
          message={notification.message}
          onClose={closeNotification}
        />
      )}
    </div>
  );
};

export default JobListPage;
