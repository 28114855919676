import axios from 'axios';

// Fetch jobs from the Netlify serverless function
export const fetchJobs = async (searchTerm, selectedLocation, nextPageToken = null) => {
    try {
        const response = await axios.get('/.netlify/functions/jobs', {
            params: {
                q: searchTerm,
                location: selectedLocation,
                pageToken: nextPageToken,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching jobs:', error);
        throw error;        
    }
};
